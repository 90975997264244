<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="sales"
                          base-table="offering"
                          :column-params="{type}"
                          columnsPrefix="common.column.sale."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :tbody-tr-class="rowClass"
                          :actions-list="getActions()"
                          :title="'common.title.' + (type || 'sales')"
                >
                    <template v-slot:top-actions>
                        <b-form inline>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-right"
                                @click="nextMonth()"
                            />
                        </b-form>
                    </template>

                    <template #cell(selected)="data">
                        <div>
                            <b-form-checkbox :key="data.item.id"
                                             v-if="data.item.selectable && !['all','filtered'].includes(selectedSource)"
                                             @change="selectRow($event, data.item)"
                                             :checked="selected.includes(data.item)"
                            ></b-form-checkbox>
                        </div>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import commonJobsSave from '@/bundles/erika_common_bundle/mixins/common-save'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'
import moment from "moment/moment";

export default {
    name: 'Sales',
    mixins: [commonTable, tableDateFilter, commonJobsSave],
    mounted() {
        this.type = this.$route.meta.type || 'kva'
        this.project_form_type = this.type == 'supply' ? 'provider' : null

        this.loading = false
        if (this.$route.query.offering) {
            this.$root.$children[0].openModal('kva-modal', {
                id: this.$route.query.offering,
                formType: this.type || null
            })
        }
    },
    data() {
        return {
            month: null,
            dateStart: null,
            dateEnd: null,
            tableOptions: {
                sortBy: 'created_at',
            },
            type: 'kva',
            project_form_type: null,
            lfFile: null,
            invoiceFile: null,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Sales', ['getSalesTable']),
        ...mapGetters('CommonData', ['getWorkflowTriggers', 'getSettings']),
        getActions() {
            if (this.type == 'return') {
                return [
                    {
                        title: this.$t('common.button.scan'),
                        icon: 'barcode',
                        click: () => {
                            this.$root.$children[0].openModal('scan-modal', {formType: 'return'}, null, {width: '800px'});
                        }
                    },
                ]
            } else {
                let actions = [];
                actions.push({
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('project-form', {type: this.project_form_type}, null, {width: '800px'})
                    }
                });
                if (!this.$root.simpleMode) {
                    actions.push({
                        title: this.$t('common.button.scan_and_print'),
                        icon: 'barcode',
                        click: () => {
                            this.$root.$children[0].openModal('scan-and-print-modal', {}, null, {width: '800px'})
                        },
                    });

                    actions.push({
                        title: this.$t('common.button.scan'),
                        icon: 'barcode',
                        click: () => {
                            this.$root.$children[0].openModal('scan-modal', {}, null, {width: '800px'})
                        },
                    });
                }
                actions.push({
                    title: this.$t('common.button.mass_update'),
                    icon: 'list',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    hide: !this.$root.hasAccess('sales', 'update'),
                    click: () => {
                        this.openChangeAttribute()
                    },
                });
                actions.push({
                    title: this.$t('common.button.deliveryLabelPrint'),
                    icon: 'gift',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.createMergedFiles('lieferschein')
                    },
                });
                actions.push({
                    title: this.lfFile ? `${this.$t('common.button.download_lf_file')}<br/>(${moment(this.lfFile.created_at).format('DD.MM.YYYY')}) ${this.lfFile?.user?.email ?? '-'}` : '',
                    icon: 'paperclip',
                    hide: !this.lfFile,
                    click: () => {
                        this.$root.openDocument(this.lfFile.filename)
                    },
                });
                actions.push({
                    title: this.$t('common.button.totalInvoicePrint'),
                    icon: 'euro-sign',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.createMergedFiles('rechnung')
                    },
                });
                actions.push({
                    title: this.invoiceFile ? `${this.$t('common.button.download_invoice_file')}<br/>(${moment(this.invoiceFile.created_at).format('DD.MM.YYYY')}) ${this.invoiceFile?.user?.email ?? '-'}` : '',
                    icon: 'paperclip',
                    hide: !this.invoiceFile,
                    click: () => {
                        this.$root.openDocument(this.invoiceFile.filename)
                    },
                });
                actions.push({
                    title: this.$t('common.button.invoiceZip'),
                    icon: 'download',
                    disabled: !this.selected.length,
                    click: () => {
                        this.downloadInvoicesZip()
                    },
                });
                if (!this.$root.simpleMode) {
                    actions.push({
                        title: this.$t('common.button.upload_amazon_report'),
                        icon: 'list',
                        click: () => {
                            this.$root.$children[0].openModal('upload-file-modal', {store_dispatch_name: 'Sales/saveArticlesMap'}, this.refreshTable, {width: '800px'});
                        },
                    });
                    actions.push({
                        title: this.$t('common.button.re_sync_orders'),
                        icon: 'sync',
                        disabled: !(this.selected.length || this.selectedSource != 'selected'),
                        hide: !this.$root.hasAccess('sales', 'update'),
                        click: () => {
                            this.reSyncOrders()
                        },
                    });
                    actions.push({
                        title: this.$t('common.button.re_generate_invoices'),
                        icon: 'sync',
                        disabled: !(this.selected.length || this.selectedSource != 'selected'),
                        hide: !this.$root.hasAccess('sales', 'update') && this.getSettings()['allow_order_edit'],
                        click: () => {
                            this.reGenerateInvoices()
                        },
                    });
                }
                actions.push({
                    title: this.$t('common.button.delete'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'trash',
                    click: () => {
                        this.deleteSales()
                    },
                });

                this.getWorkflowTriggers()['offering'].forEach(trigger => {
                    actions.push({
                        title: 'Trigger: ' + trigger.name,
                        icon: 'play',
                        disabled: !(this.selected.length || this.selectedSource != 'selected'),
                        click: () => {
                            this.loading = true

                            this.$store.dispatch('Workflow/executeTrigger', {
                                id: trigger.id,
                                ...this.getSelectedData('sales')
                            })
                                .then((response) => {
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                        },
                    },);
                })

                return actions
            }
        },
        myProvider(ctx) {
            return this.commonProviderFetch(
                ctx,
                'Sales/fetchSales',
                'getSalesTable',
                {'lfFile': 'lfFile', 'invoiceFile': 'invoiceFile'},
            )
        },
        ctxAdditionalParams() {
            return {
                'type': this.type
            }
        },
        downloadInvoicesZip() {
            let ids = this.selected.map(row => row.id);

            window.open('/sales/index/invoiceszip/ids/' + ids.join('-'))
        },
        reSyncOrders() {
            let sendData = this.getSelectedData('sales')

            this.$store.dispatch('Sales/reSyncOrders', sendData)
                .then((response) => {
                    this.commonJobsSave(response)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        reGenerateInvoices() {
            let sendData = this.getSelectedData('sales')

            this.$store.dispatch('Sales/reGenerateInvoices', sendData)
                .then((response) => {
                    this.commonJobsSave(response)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteSales() {
            let sendData = this.getSelectedData('sales')

            this.$removeConfirmAuth('Sales/deleteSales', sendData, this.commonJobsSave)
        },
        createMergedFiles(type) {
            if (this.selectedSource == 'all') {
                this.$confirm(
                    {
                        message: this.$t('common.confirmations.generate_all'),
                        button: {
                            no: this.$t('common.button.no'),
                            yes: this.$t('common.button.yes')
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.sendCreateMergedFiles(type)
                            }
                        }
                    }
                )
            } else {
                this.sendCreateMergedFiles(type)
            }
        },
        sendCreateMergedFiles(type) {
            this.loading = true
            let sendData = this.getSelectedData('sales')

            let data = {
                docType: type,
                ...sendData
            }

            this.$store.dispatch('Invoice/generateMergedFiles', data)
                .then((response) => {
                    if (response.data.batch_group) {
                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                    }
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('multichannel.text.queued_count', {count: response.data.count}),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        openChangeAttribute() {
            let sendData = this.getSelectedData('sales')

            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'offering',
                selectedData: sendData,
            }, this.refreshTable, {width: '800px'})
        },
        rowClass(item) {
            if (item && item.invoices.length > 0) {
                let row_class = '';
                item.invoices.forEach(sale => {
                    if (sale.iscanceled) {
                        row_class += ' canceled';
                    }
                })

                return row_class;
            }
        },
    },
}
</script>